import { Plugins } from '@capacitor/core';
import { alertController, IonPage, } from '@ionic/vue';
import { defineComponent, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { initOidc } from '../utils/oidc';
import { env } from '../utils/env';
const { Browser } = Plugins;
export default defineComponent({
    components: {
        IonPage,
    },
    setup() {
        const router = useRouter();
        onMounted(async () => {
            try {
                await initOidc();
                if (env.iOsOnly) {
                    Browser.close();
                }
                router.replace('/');
            }
            catch (_a) {
                const alert = await alertController.create({
                    message: 'Ein unerwarteter Fehler ist aufgetreten. Bitte probiere später noch einmal dich einzuloggen',
                    buttons: [
                        {
                            text: 'Ok',
                            handler() {
                                router.replace('/login');
                            }
                        }
                    ]
                });
                await alert.present();
            }
        });
    },
});
